import { ISegmentoApi } from "@/interfaces/SegmentoInterface";
import { AxiosResponse } from "axios";
import API from "./http-common";

class SegmentoService {
    index(): Promise<AxiosResponse<ISegmentoApi>> {
        return API.get<ISegmentoApi>('/jobs/segmentos')
    }
}

export default new SegmentoService();