import { IAuthUser, IAuthUserApi, ICreatePasswordApi, IEmpresaCredentials, IUserCreate, IUserCredentials, IUserUpdateProfile } from "@/interfaces/UserInterface";
import { AxiosResponse } from "axios";
import API from "./http-common";
import { IEndereco, IUserEndereco } from "@/interfaces/EnderecoInterface";
import { IProfileApi } from "@/interfaces/ProfileInterface";
import { IImageApi } from "@/interfaces/ImageInterface";
import { EmailValidadoApi, ValidarEmailInterface } from "@/interfaces/ValidarEmailInterface";
import { ICandidato } from "@/interfaces/CandidatoInterface";
import { IUserFacebook, IUserFacebookApi, IUserFacebookLogin, IUserFacebookLoginApi } from "@/interfaces/SocialNetwork";

class UserService {
    create(user: IUserCreate): Promise<AxiosResponse<IAuthUserApi>> {
        return API.post('/register', user)
    }

    login(credentials: IUserCredentials): Promise<AxiosResponse<IAuthUserApi>> { 
        return API.post('/login', credentials)
    }

    logout(): Promise<AxiosResponse> {
        return API.post('/logout')
    }

    delete(): Promise<AxiosResponse> {
        return API.delete('/user/delete')
    }

    forgotPassword(email: string): Promise<AxiosResponse> {
        return API.post('/esqueceu_senha', { email: email })
    }

    saveRecrutador(credentials: IEmpresaCredentials): Promise<AxiosResponse> {
        return API.post('/api/recrutador/firstAcess', credentials)
    }

    updateEndereco(endereco: IEndereco): Promise<AxiosResponse<IUserEndereco>> {
        return API.put('/api/user/endereco', endereco)
    }

    getProfile(): Promise<AxiosResponse<IAuthUserApi>> {
        return API.get('/profile')
    }

    getProfileByUuid(uuid: string): Promise<AxiosResponse<IProfileApi>> {
        return API.post('/profile/uuid', { 'uuid': uuid });
    }

    createPassword(password: ICreatePasswordApi): Promise<AxiosResponse<IAuthUserApi>> {
        return API.post('/cadastrar-senha', password)
    }

    recoverPassword(password: ICreatePasswordApi): Promise<AxiosResponse> {
        return API.post('/recuperar-senha', password)
    }

    uploadAvatar(avatar: File): Promise<AxiosResponse<IImageApi>> {
        let formData = new FormData()
        formData.append('avatar', avatar);
        return API.post('/user/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    updateProfile(user: IAuthUser): Promise<AxiosResponse<IAuthUserApi>> { 
        return API.put('/profile/update', user);
    }

    enviarEmailValidacao(): Promise<AxiosResponse> {
        return API.post('/user/email/validar/enviar');
    }

    validarEmail(parametros: ValidarEmailInterface): Promise<AxiosResponse<EmailValidadoApi>> {
        return API.post(`/user/email/validar/verificar/${parametros.id}/${parametros.hash}`, null, {
            params: {
                expires: parametros.expires,
                signature: parametros.signature
            }
        })
    }
    loginFacebook(): Promise<AxiosResponse<IUserFacebookLoginApi>> {
        return API.get('/auth/facebook/redirect');
    }
    
    callBackFacebook(code: string): Promise<AxiosResponse> {
        return API.get(`/auth/facebook/callback`, {
            params: {
                code: code
            },
        })
    }
    loginGoogle(): Promise<AxiosResponse<IUserFacebookLoginApi>> {
        return API.get('/auth/google/redirect');
    }
    
    callBackGoogle(code: string): Promise<AxiosResponse> {
        return API.get(`/auth/google/callback`, {
            params: {
                code: code,
                scope: 'email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
                authuser: '0',
                prompt: 'consent'
            },
        })
    }
    loginLinkedin(): Promise<AxiosResponse<IUserFacebookLoginApi>> {
        return API.get('/auth/linkedin/redirect');
    }
    
    callBackLinkedin(code: string): Promise<AxiosResponse> {
        return API.get(`/auth/linkedin/callback`, {
            params: {
                code: code,
            },
        })
    }
}

export default new UserService();