import { IAuthUser } from '@/interfaces/UserInterface';
import axios, { AxiosInstance } from 'axios';

const API: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
})

API.interceptors.request.use(
    (config) => {
        const authUser = JSON.parse(<string>localStorage.getItem('vueAuthUser')) as IAuthUser 
        if (authUser.token) {
            config.headers.Authorization = `Bearer ${authUser.token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default API