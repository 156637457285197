import { IValidateSimpleRequest, IValidationApi, IValidationUniqueRequest } from "@/interfaces/ValidationInterface"
import { AxiosResponse } from "axios"
import { ref } from "vue";

const countTime = ref<number>(0);

export const required = (message?: string) => (value: string) => !!value || message || 'Este campo é obrigatório'
export const notRequired = (message?: string) => (value: string) => value || message || 'Este campo é obrigatório'
export const requiredIf = (condition: boolean, message?: string) => (value: string) => condition && (!!value || message || 'Este campo é obrigatório')

export const validateDate = (endDate:string,message?: string) => (value:string) => !endDate || Date.parse(value) >= Date.parse(endDate) || message || 'Data início maior que a data término'
export const requiredWithoutAll = (fields: number[],campos:string[],message?: string) => () => isEmptyArray(fields) || message || 'Este campo é obrigatório quando nenhum dos campos é preenchido ( '+ campos.join(', ')+')';
function isEmptyArray(value: number[]) {
  return value.some(e => e !== 0);
} 
export const dataInvalida = (message?: string) => (value:string) => !isNaN(Date.parse(value)) || message || 'Data inválida'


export const min = (length: number, message?: string) => (value: string) => !!value && value.length >= length || message || `Este campo deve ter ao menos ${length} caracteres`

export const max = (length: number, message?: string) => (value: string) => (!!value && value.length <= length) || message || `Este campo deve ter no máximo ${length} caracteres`

export const minSelectedCount = (min: number = 1, message?:string) => (value: string) => {
    return Object.keys(value).length >= min || message || `Selecione pelo menos ${min} opções`
}

export const validateUrl = (url: string) => /^(ftp|http|https):\/\/[^ "]+$/.test(url);

export const urlRule = (message = "URL invalida") => (value: string | null | undefined) =>
    !value || validateUrl(value) || message;

export const size = (size: number, message?: string) => (value: string) => (!!value && value.length === 8) || message || `Este campo deve ter ${size} caracteres`

export const sizeBetween = (min: number, max: number, message?: string) => (value: string) => (!!value && value.length >= min && value.length <= max) || message || `Este campo deve ter entre ${min} e ${max} caracteres.`

export const email = (message?: string) => (value: string) => (
    ['uol.com.br', 'terra.com.br', 'yahoo.com', 'gmail.com'].includes(value.split('@')[1])
      ? 'Por favor, insira um email coorporativo para seguir com o cadastro'
      : (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
          ? true
          : message || 'Endereço de e-mail informado não é válido'
      )
  );

export const passwordConfirmation = (password: string, message?: string) => (value: string) => (!!value && value === password) || message || 'As senhas digitadas não conferem.'

export const passwordStrength = (length: number, message?: string) => (value: string) => (!!value && passwordStrengthCheck(value, length)) || message || 'A senha deve informada não atende aos critérios de segurança mínimos estabelecidos. Precisa conter pelo menos 1 letra minúscula, 1 MAIÚSCULA, 1 número e 1 caractere especial (!, @, #, $, %, ^, &, * ou .)'

export const uniqueApi = (callback: (data: IValidationUniqueRequest) => Promise<AxiosResponse<IValidationApi>>, options: IValidationUniqueRequest, message?: string) => {
    return async (value: string): Promise<string | boolean> => {
        const res = await callback({ ...options, value })
        return res.data.valid || message || 'Informação já existe no sistema, não é permitido duplicidade deste valor.'
    }
}

export const existsApi = (callback: (data: IValidationUniqueRequest) => Promise<AxiosResponse<IValidationApi>>, options: IValidationUniqueRequest, message?: string) => {
    return async (value: string): Promise<string | boolean> => {
        const res = await callback({ ...options, value })
        return !res.data.valid || message || 'Não foi possível encontrar essa informação em nossos registros.'
    }
}

export const validateApi = (callback: (data: IValidateSimpleRequest) => Promise<AxiosResponse>, message?: string) => {
    return async (value: string): Promise<string | boolean> => {
        const res = await callback({ value })
        return res.data.valid || message || 'Valor informado não é válido para este campo.'
    }
}


function passwordStrengthCheck(password: string, length: number = 8): boolean {
    return new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.]).{${length},}$`).test(password)
}
