import { ICargoApi } from "@/interfaces/CargoInterface";
import { AxiosResponse } from "axios";
import API from "./http-common";

class CargoService {
    index(): Promise<AxiosResponse<ICargoApi>> {
        return API.get<ICargoApi>('/jobs/funcoes')
    }
}

export default new CargoService();