import { AxiosResponse } from "axios";
import API from "./http-common"; 
import { IRecrutadorApi } from "@/interfaces/RecrutadorInterface";

class RecrutadorService {
    
    getRecrutador(): Promise<AxiosResponse<IRecrutadorApi>> {
        return API.get('/recrutador');
    }
     
}

export default new RecrutadorService();