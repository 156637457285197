import type { IAuthUser, IAuthUserApi } from "@/interfaces/UserInterface";
import { defineStore } from "pinia";
import { useLocalStorage } from '@vueuse/core';
import UserService from '@/services/userService';
import { Axios, AxiosResponse } from "axios";
import { EnderecoEnum } from "@/enums/enderecoEnum";
import { Profile } from "@/enums/profileEnum";
import { IEndereco, IUserEndereco } from "@/interfaces/EnderecoInterface";
import user from "@/services/userService";
import { useCandidatoStore } from "./candidatoStore";
import { useRecrutadorStore } from "./recrutadorStore";
import { IUserFacebook, IUserFacebookApi } from "@/interfaces/SocialNetwork";
import { useRoute, useRouter } from "vue-router";
export type AuthStoreType = {
    authUser: IAuthUser | null;
}

const authUserInitialState: IAuthUser = {} as IAuthUser

const EnderecoInitialState: IUserEndereco = {} as IUserEndereco

export const useAuthStore = defineStore('authStore', {
    state: () => (
        {
            authUser: useLocalStorage<IAuthUser>('vueAuthUser', authUserInitialState), 
            candidato: useCandidatoStore(),
            recrutador: useRecrutadorStore(),
            email: '',
            password: '',
            code: '',
            route: '',
            keepConnected: false,
            errors: {
                message: ''
            }
        }
    ),
    getters: {
        userAvatar(): string { 
            return this.authUser.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&size=32&name=${this.authUser.first_name}`
        },
        userIcone(): string {
            return this.authUser.avatar || `https://ui-avatars.com/api/?rounded=true&background=random&size=200&name=${this.authUser.first_name}`
        }, 
        enderecoPrincipal(): IUserEndereco {
            if (this.authUser.enderecos && this.authUser.enderecos.length) {
                return this.authUser.enderecos.filter(endereco => endereco.tipo_endereco === EnderecoEnum.PRINCIPAL)[0]
            }
            return EnderecoInitialState
        }
    },
    actions: {
        setAuthUser(user: IAuthUser) {  
            this.authUser = user   
            if(this.authUser.profile){
                if (this.authUser.profile.id === Profile.CANDIDATO) {
                    this.candidato.getCandidato() 
                } else if(this.authUser.profile.id === Profile.RECRUTADOR) { 
                    this.recrutador.getRecrutador()
                }
            } 
        },
        updateProfile(){ 
           UserService.updateProfile(this.authUser).then((response:AxiosResponse<IAuthUserApi>)=>{ 
                this.setAuthUser(response.data.data)
                this.candidato.getCandidato()  
                window.location.href = '/candidato/home'; 
           })
        },
        setErrors(errors: { message: '' }) {
            return this.errors = <{ message: string }>errors
        },

        loginErrors(): { hasErrors: boolean, message: string} {
            return { hasErrors: !!this.errors.message, message: this.errors.message } 
        }, 
        delete() { 
            UserService.delete().then((response: AxiosResponse<IAuthUserApi>) => {
                const id = this.authUser.profile.id;
                this.setErrors({ message: '' }) 
                this.setAuthUser(authUserInitialState)  
                localStorage.clear();
                this.email = ''
                this.password = ''
                this.keepConnected = false

                if(id == Profile.RECRUTADOR){
                    window.location.href = '/recrutador/login?deleted=true'; 
                }else{
                    window.location.href = '/login?deleted=true'; 
                }
            }).catch((error) => {
                this.setErrors(error.response.data)
            })
        }, 
        login() { 
            UserService.login({
                email: this.email,
                password: this.password,
                keepConnected: this.keepConnected
            }).then((response: AxiosResponse<IAuthUserApi>) => {
                this.setAuthUser(response.data.data)
                this.candidato.getCandidato()

                if (this.authUser.profile.id === Profile.CANDIDATO) {
                    this.candidato.getCandidato()
                    // const candidatoStore = useCandidatoStore();
                    // candidatoStore.getCandidato()
                }
                this.router.push({
                    name: 'home-usuario'
                })
            }).catch((error) => {
                this.setErrors(error.response.data)
            })
        }, 
        logout() {
            UserService.logout().then(
                (response: AxiosResponse) => { 
                    this.setErrors({ message: '' }) 
                    this.setAuthUser(authUserInitialState) 
                    localStorage.clear(); 
                    this.email = ''
                    this.password = ''
                    this.keepConnected = false                    
                }
            ).catch((error) => {
                localStorage.clear(); 
            })
            .finally(() => {
                { 
                    this.router.push({
                        name: 'home'
                    })
                }
            })
        },
        async loginFacebookCallback(code: string): Promise<void> {
            try {
              const response = await UserService.callBackFacebook(code);

            localStorage.setItem('token', response.data.data.token);
          
            this.setAuthUser(response.data.data)

            this.candidato.getCandidato()

            if (this.authUser.profile.id === Profile.CANDIDATO) {
                this.candidato.getCandidato()
            }
            this.router.push({
                name: 'home-usuario'
            })
            } catch (error) {
              console.log(error);
            }
          },
        
        loginFacebook() { 
            UserService.loginFacebook().then((response: AxiosResponse) => {
     
            window.location.href = response.data.url;
 
            }).catch((error) => {
                this.setErrors(error.response.data)
            })
        },
        loginGoogle() { 
            UserService.loginGoogle().then((response: AxiosResponse) => {
     
            window.location.href = response.data.url;
 
            }).catch((error) => {
                this.setErrors(error.response.data)
            })
        },
        
        async loginGoogleCallback(code: string): Promise<void> {
            try {
            const response = await UserService.callBackGoogle(code);

            localStorage.setItem('token', response.data.data.token);
          
            this.setAuthUser(response.data.data)
            this.candidato.getCandidato()


            if (this.authUser.profile.id === Profile.CANDIDATO) {
                this.candidato.getCandidato()
            }
            this.router.push({
                name: 'home-usuario'
            })
            } catch (error) {
              console.log(error);
            }
          },
        
          loginLinkedin() { 
            UserService.loginLinkedin().then((response: AxiosResponse) => {
     
            window.location.href = response.data.url;
 
            }).catch((error) => {
                this.setErrors(error.response.data)
            })
        },
        
        async loginLinkedinCallback(code: string): Promise<void> {
            try {
            const response = await UserService.callBackLinkedin(code);

            localStorage.setItem('token', response.data.data.token);
          
            this.setAuthUser(response.data.data)
            this.candidato.getCandidato()

            if (this.authUser.profile.id === Profile.CANDIDATO) {
                this.candidato.getCandidato()
            }
            this.router.push({
                name: 'home-usuario'
            })
            } catch (error) {
              console.log(error);
            }
          },
          
        async onUpdateEndereco(endereco: IEndereco): Promise<void> {
           const response: IUserEndereco = (await user.updateEndereco(endereco)).data
           this.$patch({authUser: {enderecos: [response]}})
        }
    }
})