import { ICandidato, ICandidatoApi, ICandidatoConcluirCadastroApi, ICandidatoExperiencia, ICandidatoFuncao } from "@/interfaces/CandidatoInterface";
import { AxiosResponse } from "axios";
import API from "./http-common";
import { ICargoSelecionado, IExperienciaSelecionada } from "@/interfaces/CargoInterface";
import { IEmpresa } from "@/interfaces/Empresa";

class CandidatoService {
    getCandidato(): Promise<AxiosResponse<ICandidatoApi>> {
        return API.get('/candidato');
    }
    
    getByUuid(uuid: string): Promise<AxiosResponse<ICandidatoConcluirCadastroApi>> {
        return API.get(`/candidato/uuid/${uuid}`)
    }

    salvarCargos(cargos: Array<ICargoSelecionado>): Promise<AxiosResponse<Array<ICandidatoFuncao>>> {
        return API.post('/candidato/salvar-cargos-selecionados', {selecionados: cargos})
    }  
    
    salvarExperiencias(experiencias: Array<IExperienciaSelecionada>): Promise<AxiosResponse<Array<ICandidatoExperiencia>>> {
        return API.post('/candidato/salvar-expericiencias-selecionadas', {experienciasSelecionadas: experiencias})
    }     
    
    atualizarCadastroCandidato(candidato: ICandidato): Promise<AxiosResponse<ICandidatoApi>> {
        return API.put('/candidato/update', candidato);
    }

    incluirInformacoesComplementares(data: ICandidato): Promise<AxiosResponse<ICandidatoApi>> {
        return API.put('/candidato/dados-complementares', data);
    }
}

export default new CandidatoService();