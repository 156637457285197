import { AxiosResponse } from "axios";
import API from "./http-common";
import { IOrientacaoSexualApi } from "@/interfaces/tipos/OrientacaoSexualInterface";
import { ITipoDeficienciaApi } from "@/interfaces/tipos/TipoDeficienciaInterface";

class TiposService {
    orientacaoSexual(): Promise<AxiosResponse<IOrientacaoSexualApi>> {
        return API.get<IOrientacaoSexualApi>('/enums/orientacao-sexual')
    }

    deficiencias(): Promise<AxiosResponse<ITipoDeficienciaApi>> {
        return API.get<ITipoDeficienciaApi>('/enums/deficiencias')
    }
}

export default new TiposService();