<template>
  <div class="h-100">
    
    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-12 col-lg-10 col-xl-8">
            <div class="authincation-content">
              
              <div class="auth-form">
                <v-alert v-if="route.query.deleted === 'true'"
                  type="success"
                  title="Sua conta foi excluída com sucesso!"
                  text=""
                ></v-alert> 
                <div class="d-flex justify-content-center mb-3">
                  <div class="rounded-logo"></div>
                </div>
                <h4 class="text-center mb-4">Entre com a sua conta</h4>
                <v-form @submit.prevent="store.login()">
                  <v-text-field
                    label="Email"
                    v-model="store.email"
                    type="email"
                    :rules="[required(), email()]"
                    :error="!store.errors"
                    :error-messages="store.errors.message"
                  />
                  <v-text-field
                    label="Senha"
                    v-model="store.password"
                    :append-inner-icon="
                      showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'
                    "
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[required()]"
                    @click:append-inner="() => (showPassword = !showPassword)"
                  />
                  <v-row justify="space-between" class="mb-2">
                    <v-checkbox
                      v-model="store.keepConnected"
                      label="Manter conectado"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <div
                      class="d-flex justify-content-end align-items-center pr-3"
                    >
                      <router-link to="/login/esqueceu-a-senha"
                        >Esqueceu a senha?</router-link
                      >
                    </div>
                  </v-row>
                  <!-- <a style="color: #fc744c" href="/Termo_Uso_Izy_Job.pdf" target="_blank">Termos e condições de uso</a> -->
                  <termos-uso />
                  <v-btn
                    type="submit"
                    rounded="lg"
                    size="large"
                    color="primary"
                    class="btn-block mt-3"
                  >
                    Entrar
                  </v-btn>
                </v-form>
                <div class="new-account mt-3">
                  <p>
                    Ainda não tem uma conta?
                    <router-link
                      v-if="isRecrutador != true"
                      to="/cadastro-recrutador"
                      class="text-primary"
                      >Cadastre-se!</router-link
                    >
                    <router-link
                      v-else
                      to="/cadastro-candidato"
                      class="text-primary"
                      >Cadastre-se!</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import { ref } from "vue";
import { email, required } from "@/validation/rules";
import TermosUso from "@/components/layout/TermosUso.vue";

const store = useAuthStore();

const route = useRoute();

const showPassword = ref<boolean>(false);
const valid = ref<boolean>(false);

const isRecrutador = route.path != "/recrutador/login" ? true : false;
const bgClass =
  route.path == "/recrutador/login" ? "bg-login-recrutador" : "bg-cadastro";
</script>
