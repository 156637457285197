 
import { AxiosResponse } from "axios"; 
import { IEstadoApi } from "@/interfaces/EstadoInterface";
import API from "./http-common";

class EstadoService {
    index(): Promise<AxiosResponse<IEstadoApi>> {
        return API.get<IEstadoApi>('/estados')
    }
}

export default new EstadoService();