import { IRecrutador, IRecrutadorApi } from "@/interfaces/RecrutadorInterface";   
import recrutadorService from "@/services/recrutadorService";
import { useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia"; 
const createEmpryRecrutador = (): IRecrutador => {
    return {
        id: 0,
        user_id: 0,
        empresa_id: 0, 
    }
}

export const useRecrutadorStore = defineStore('recrutadorStore', {  
    state: () => (
        {
            recrutador: useLocalStorage<IRecrutador>('vueRecrutador', createEmpryRecrutador())
        }  
    ),
    actions: {
        async getRecrutador(): Promise<AxiosResponse<IRecrutadorApi>> {
            const dataService = (await recrutadorService.getRecrutador());
            this.recrutador = dataService.data.data
            this.$patch({ recrutador: this.recrutador });   
            return dataService;
        },
    }
})