import { IArea } from "@/interfaces/AreaInterface";
import { ICandidato, ICandidatoApi, ICandidatoFuncao } from "@/interfaces/CandidatoInterface";
import { ICargo } from "@/interfaces/CargoInterface";
import { IEstado } from "@/interfaces/EstadoInterface";
import { ISegmento } from "@/interfaces/SegmentoInterface";
import { IOrientacaoSexual } from "@/interfaces/tipos/OrientacaoSexualInterface";
import { ITipoDeficiencia } from "@/interfaces/tipos/TipoDeficienciaInterface";
import areaService from "@/services/areaService";
import candidatoService from "@/services/candidatoService";
import cargoService from "@/services/cargoService";
import estadoService from "@/services/estadoService";
import mesoregiaoService from "@/services/mesoregiaoService";
import segmentoService from "@/services/segmentoService";
import tiposService from "@/services/tiposService";
import { defineStore } from "pinia";
import { Ref, ref, computed } from "vue";

const createEmptyCandidato  = {} as ICandidato;

export const useCandidatoStore = defineStore('candidatoStore', () => {
    const candidato: Ref<ICandidato> = ref<ICandidato>(createEmptyCandidato)
    const funcoes: Ref<Array<ICargo>> = ref<Array<ICargo>>([])
    const areas: Ref<Array<IArea>> = ref<Array<IArea>>([])
    const estados: Ref<Array<IEstado>> = ref<Array<IEstado>>([])
    const mesoregiao: Ref<Array<IEstado>> = ref<Array<IEstado>>([])
    const segmentos: Ref<Array<ISegmento>> = ref<Array<ISegmento>>([])
    const orientacaoSexualList: Ref<Array<IOrientacaoSexual>> = ref<Array<IOrientacaoSexual>>([])
    const TipoDeficienciaList: Ref<Array<ITipoDeficiencia>> = ref<Array<ITipoDeficiencia>>([])
     
    async function getCandidato(): Promise<void> {
        candidato.value = (await candidatoService.getCandidato()).data.data   
    }

    function setFuncoes(funcoes: Array<ICandidatoFuncao>): void {
        candidato.value.funcoes = funcoes
    }

    async function getFuncoes(): Promise<void> {
        funcoes.value = (await cargoService.index()).data.data
    }

    async function getAreas(): Promise<void> {
        funcoes.value = (await areaService.index()).data.data
    }
    async function getEstados(): Promise<void> {
        estados.value = (await estadoService.index()).data.data
    }
    async function getMesoregiao(uf: string): Promise<void> {
        mesoregiao.value = (await mesoregiaoService.index(uf)).data.data
    }
    async function getSegmentos(): Promise<void> {
        funcoes.value = (await segmentoService.index()).data.data
    }

    async function getOrientacaoSexualList(): Promise<void> {
        orientacaoSexualList.value = (await tiposService.orientacaoSexual()).data.data
    }

    async function getTipoDeficienciaList(): Promise<void> {
        TipoDeficienciaList.value = (await tiposService.deficiencias()).data.data
    }

    return {
        candidato,
        funcoes,
        areas,
        estados,
        segmentos,
        orientacaoSexualList,
        TipoDeficienciaList,
        getEstados,
        mesoregiao,
        getCandidato,
        setFuncoes,
        getMesoregiao,
        getFuncoes,
        getAreas,
        getSegmentos,
        getOrientacaoSexualList,
        getTipoDeficienciaList
    }
})