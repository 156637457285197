 
import { AxiosResponse } from "axios"; 
import { IEstadoApi } from "@/interfaces/EstadoInterface";
import API from "./http-common";

class MesoregiaoService {
    index(uf:string): Promise<AxiosResponse<IEstadoApi>> {
        return API.get<any>('/mesorregioes/'+uf)
    }
}

export default new MesoregiaoService();