<template>
    <div>
        <small class="h6">
            <a data-bs-toggle="modal" data-bs-target="#modal-termos-uso" class="text-primary" style="cursor: pointer;">Clique aqui</a> para ver
            nossos
            termos e condições.
        </small>
        <div class="modal fade" id="modal-termos-uso" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-body p-2">
                        <div class="text-center">
                            <embed :src="termoUrl" width="100%" height="600px" type="application/pdf">
                        </div>
                    </div>
                    <div class="modal-footer p-2">
                        <v-btn type="button" id="btnFechar" color="primary" data-bs-dismiss="modal">FECHAR</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const termoUrl = new URL('/Termo_Uso_Izy_Job.pdf', import.meta.url).href
</script>
