import { IAreaApi } from "@/interfaces/AreaInterface";
import { AxiosResponse } from "axios";
import API from "./http-common";

class AreaService {
    index(): Promise<AxiosResponse<IAreaApi>> {
        return API.get<IAreaApi>('/jobs/areas')
    }
}

export default new AreaService();