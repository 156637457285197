<template>
  <div class="row h-100">
    <div :class="bgClass" class="col min-h-100 hide-mobile"></div>
    <div class="col min-h-100">
   
      <router-view v-slot="{ Component, route }">
        
        <CustomTransition>
          <component :is="Component" />  
          
        </CustomTransition>
      </router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomTransition from "@/components/layout/CustomTransition.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const bgClass =
  route.path != "/recrutador/login" ? "bg-login" : "bg-login-recrutador";
</script>